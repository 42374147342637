/* eslint-disable react/button-has-type */
/* eslint-disable new-cap */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useQuery } from 'react-query';
import { useAuthUser, useSignOut } from 'react-auth-kit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  Card,
  Stack,
  Button,
  MenuItem,
  Container,
  Typography,
  Divider,
  TextField,
  Alert,
  CircularProgress,
  Box,
} from '@mui/material';

import QRCode from "react-qr-code";
import { useForm } from 'react-hook-form';
import Iconify from '../components/iconify';
import { createTool, getLocations } from '../utils/api';

export default function NewTool() {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { data: locationList, isLoading: locationLoading, refetch } = useQuery('locations', () => getLocations(token, signOut));
  const [toolPhotoRequired, setToolPhotoRequired] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Add the loading state
  const [isToolCreated, setIsToolCreated] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [isPDFReady, setIsPDFReady] = useState(false);

  useEffect(() => {
    if (isToolCreated) {
      setIsPDFReady(true);
    }
  }, [isToolCreated]);

  const exportToPDF = async () => {
    const element = document.getElementById('qrCodeContainer');
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: 'a4',
    });

    const marginTop = 30;
    const qrCodeMarginTop = 20;

    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const qrCodeHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'PNG', 0, marginTop + qrCodeMarginTop, pdfWidth, qrCodeHeight);
    pdf.save('tool_qr_code.pdf');
  };

  const resetMessages = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };

  const auth = useAuthUser();
  const signOut = useSignOut();

  const { token } = auth();

  const onSubmit = async (data) => {
    resetMessages();
    setIsLoading(true); // Set loading to true when submitting the form

    const { newToolName, toolLocation, toolPhotoRequired, toolSerial, toolPartNr } = data;

    try {
      console.log('data', data);
      await createTool(newToolName, toolLocation, toolPhotoRequired, toolSerial, toolPartNr, token, signOut);
      setSuccessMessage('Tool was created successfully!'); // Set the success message
      const photoRequired = toolPhotoRequired ? 'True' : 'False';
      const qrCode = `${newToolName};${toolSerial};${toolPartNr};${toolLocation};${photoRequired}`;
      setQrCode(qrCode);
      setIsToolCreated(true);
      setIsPDFReady(true);


      reset();
    } catch (error) {
      setErrorMessage(error.message || 'Something went wrong. Please try again!');
    } finally {
      setIsLoading(false); // Set loading to false when the operation is complete
    }
  };

  return (
    <>
      <Helmet>
        <title> New Tool | GMT ehf </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            New Tool
          </Typography>
        </Stack>
        <Card>
          {successMessage && (
            <Alert severity="success">
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="error">
              {errorMessage}
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} sx={{ p: 3 }}>
              <Stack direction="row" spacing={2}>
                <TextField
                  {...register('newToolName', { required: true })}
                  name="newToolName"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newToolName}
                  helperText={errors.newToolName && 'Please enter a name for this tool'}
                />
                <TextField // dropdown af locations
                  {...register('toolLocation', { required: true })}
                  name="toolLocation"
                  label="Location"
                  variant="outlined"
                  fullWidth
                  select
                  autoComplete="off"
                  error={!!errors.newLastName}
                  helperText={errors.newLastName && 'Please select a location'}
                  SelectProps={{
                    displayEmpty: true,
                    defaultValue: ''
                  }}
                >
                  {locationList && locationList.map((location) => (
                    <MenuItem key={location.id} value={location.code}>{location.name} / {location.code} / {location.country} </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction="row" spacing={2}>

                <TextField // dropdown yes/no
                  {...register('toolPhotoRequired', { required: true })}
                  name="toolPhotoRequired"
                  label="Photo Required?"
                  variant="outlined"
                  fullWidth
                  select
                  SelectProps={{
                    displayEmpty: true,
                    defaultValue: 'false'
                  }}
                  value={toolPhotoRequired}
                  onChange={(e) => setToolPhotoRequired(e.target.value === 'true')}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>

                <TextField
                  {...register('toolSerial', { required: true })}
                  name="toolSerial"
                  label="Serial"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.toolSerial}
                  helperText={errors.toolSerial && 'Please enter a serial number'}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  {...register('toolPartNr', { required: true })}
                  name="toolPartNr"
                  label="Part Nr."
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newPhone}
                  helperText={errors.newPhone && 'Please enter a part number for this tool'}
                />
                {/* <TextField
                  {...register('newEmail', { required: true, pattern: /^\S+@\S+$/i })}
                  name="newEmail"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  autoComplete="off"
                /> */}
              </Stack>

            </Stack>
            <br /><br />
            {isLoading ? ( // Render the circular progress bar if loading is true
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end', // Center align QRCode
                  // marginTop: '20px', // Add some spacing above the QRCode
                  marginBottom: '20px', // Add some spacing below the QRCode
                  marginRight: '20px'
                }}
              >
                <Button
                  variant="contained"
                  disabled
                  startIcon={<CircularProgress size={20} />} // Add the circular progress icon
                >
                  Create
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end', // Center align QRCode
                  // marginTop: '20px', // Add some spacing above the QRCode
                  marginBottom: '20px', // Add some spacing below the QRCode
                  marginRight: '20px'
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Create
                </Button>
              </Box>
            )}


          </form>
          {isToolCreated && (
            <>
              <div id="qrCodeContainer" style={{ textAlign: 'center' }}>
                <Stack
                  sx={{
                    alignItems: 'center', // This ensures center alignment
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                  spacing={2} // This adds space between the QR code and the text
                >
                  <QRCode
                    size={256} // Adjusted for better visibility
                    value={qrCode}
                    level="Q" // Adjust QR code error correction level if needed
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </Stack>
              </div>
              <Typography variant="body1" style={{ maxWidth: "100%", wordWrap: "break-word", textAlign: "center" }}>
                {qrCode}
              </Typography>
            </>
          )}
          {isPDFReady && (
            <Box sx={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                endIcon={<PictureAsPdfIcon />} // Using the MUI PDF icon
                onClick={exportToPDF}
              >
                Export
              </Button>
            </Box>
          )
          }
        </Card>
      </Container >
    </>
  );
}
