/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useAuthUser, useSignOut } from 'react-auth-kit';
import { useQuery } from 'react-query';
import {
  Card,
  Stack,
  Button,
  MenuItem,
  Container,
  Typography,
  Divider,
  TextField,
  Alert,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormGroup,

} from '@mui/material';

import { useForm } from 'react-hook-form';
import Iconify from '../components/iconify';
import { createEmployee, getLocations } from '../utils/api';

export default function NewUserPage() {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [isAdmin, setIsAdmin] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Add the loading state
  const { data: projectListData, isLoading: isLoadingPlanes, refetch } = useQuery('locations', () => getLocations(token, signOut));
  const [selectedLocations, setSelectedLocations] = useState({});

  useEffect(() => {
    if (projectListData) {
      const initialLocations = projectListData.reduce((acc, location) => ({
        ...acc,
        [location.id]: false
      }), {});
      setSelectedLocations(initialLocations);
    }
  }, [projectListData]);

  const handleLocationChange = (event) => {
    const { name, checked } = event.target;
    setSelectedLocations((prevLocations) => ({
      ...prevLocations,
      [name]: checked,
    }));
  };

  const resetMessages = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };

  const auth = useAuthUser();
  const signOut = useSignOut();

  const { token } = auth();

  const onSubmit = async (data) => {
    resetMessages();
    setIsLoading(true); // Set loading to true when submitting the form

    const { newFirstName, newLastName, newKennitala, newPassword, newPhone, newEmail } = data;
    const fullName = `${newFirstName} ${newLastName}`;
    const firstName = newFirstName;
    const lastName = newLastName;
    const kennitala = newKennitala;
    const password = newPassword;
    const phone = newPhone;
    const email = newEmail;
    const locations = Object.entries(selectedLocations).reduce((selected, [key, value]) => {
      if (value) selected.push(key);
      return selected;
    }, []);
    const admin = isAdmin;

    try {
      console.log(firstName, lastName, kennitala, password, phone, email, locations, admin, token, signOut);
      await createEmployee(firstName, lastName, kennitala, password, phone, email, locations, admin, token, signOut);
      setSuccessMessage('Employee created successfully!'); // Set the success message when the operation is complete
      reset();
    } catch (error) {
      setErrorMessage(error.message || 'Something went wrong! Please try again.');
    } finally {
      setIsLoading(false); // Set loading to false when the operation is complete
    }
  };

  return (
    <>
      <Helmet>
        <title> New Employee | GMT ehf </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            New Employee
          </Typography>
        </Stack>
        <Card>
          {successMessage && (
            <Alert severity="success">
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="error">
              {errorMessage}
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} sx={{ p: 3 }}>
              <Stack direction="row" spacing={2}>
                <TextField
                  {...register('newFirstName', { required: true })}
                  name="newFirstName"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newFirstName}
                  helperText={errors.newFirstName && 'Please enter a first name'}
                />
                <TextField
                  {...register('newLastName', { required: true })}
                  name="newLastName"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newLastName}
                  helperText={errors.newLastName && 'Please enter a last name'}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  {...register('newKennitala', { required: true, maxLength: 6 })}
                  name="newKennitala"
                  label="Employee Number"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newKennitala}
                  helperText={errors.newKennitala && 'Please enter a valid employee number'}
                />
                <TextField
                  {...register('newPassword', { required: true })}
                  name="newPassword"
                  label="Password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  autoComplete="new-password"
                  error={!!errors.newPassword}
                  helperText={errors.newPassword && 'Please enter a password'}

                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  {...register('newPhone', { required: true, maxLength: 7, pattern: /[0-9]{7}/ })}
                  name="newPhone"
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newPhone}
                  helperText={errors.newPhone && 'Please enter a phone number'}
                />
                <TextField
                  {...register('newEmail', { required: true, pattern: /^\S+@\S+$/i })}
                  name="newEmail"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  autoComplete="new-email"
                  error={!!errors.newEmail}
                  helperText={errors.newEmail && 'Please enter a email'}
                />
              </Stack>

              <Divider sx={{ my: 3 }}>Location</Divider>
              <FormGroup row sx={{ justifyContent: 'center' }}>
                {projectListData && projectListData.map((location) => (
                  <FormControlLabel
                    key={location.id}
                    control={
                      <Checkbox
                        checked={selectedLocations[location.id] || false}
                        onChange={handleLocationChange}
                        name={location.id}
                      />
                    }
                    label={location.name}
                  />
                ))}
              </FormGroup>

              <Divider sx={{ my: 3 }}>Admin?</Divider>

              <TextField
                {...register('isAdmin')}
                name="isAdmin"
                variant="outlined"
                select
                fullWidth
                SelectProps={{
                  displayEmpty: true,
                  defaultValue: 'false'
                }}
                value={isAdmin}
                onChange={(e) => setIsAdmin(e.target.value === 'true')}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>
              {errors.isAdmin && <span>You forgot to select here!</span>}
            </Stack>
            <br /><br />
            {isLoading ? ( // Render the circular progress bar if loading is true
              <Button
                variant="contained"
                disabled
                startIcon={<CircularProgress size={20} />} // Add the circular progress icon
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  margin: '10px',
                }}
              >
                Create
              </Button>
            ) : (
              <Button
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:plus-fill" />}
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  margin: '10px',
                }}
              >
                Create
              </Button>
            )}
          </form>
        </Card>
      </Container>
    </>
  );
}
