import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import SendIcon from '@mui/icons-material/Send';
import { AirplanemodeActive, Public, HomeRepairService, Dangerous, Build, Add, RotateRight } from '@mui/icons-material';

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: <DashboardIcon />,
    category: 'main',
  },
  {
    title: 'Employees',
    path: '/dashboard/user',
    icon: <PersonIcon />,
    category: 'main',
  },
  {
    title: 'Projects',
    path: '/dashboard/project',
    icon: <LockOpenIcon />,
    category: 'projects',
  },
  {
    title: 'Closed Projects',
    path: '/dashboard/closed',
    icon: <LockIcon />,
    category: 'projects',
  },
  {
    title: 'Employee Tools',
    path: '/dashboard/employee-tools',
    icon: <Build />,
    category: 'projects',
  },
  {
    title: 'Tool Inventory',
    path: '/dashboard/tools',
    icon: <HomeRepairService />,
    category: 'main',
  },
  {
    title: 'New Tool',
    path: '/dashboard/new-tool',
    icon: <Add />,
    category: 'main',
  },
  {
    title: 'Tools In/Out',
    path: '/dashboard/in-out',
    icon: <RotateRight />,
    category: 'projects',
  },
  {
    title: "U\\S",
    path: '/dashboard/broken-tools',
    icon: <Dangerous />,
    category: 'maintenance',
  },
  {
    title: 'Airplanes',
    path: '/dashboard/airplanes',
    icon: <AirplanemodeActive />,
    category: 'main',
  },
  {
    title: 'Locations',
    path: '/dashboard/locations',
    icon: <Public />,
    category: 'main',
  },
  {
    title: 'Notifications',
    path: '/dashboard/push-notifications',
    icon: <SendIcon />,
    category: 'main',
  },
];

export default navConfig;
