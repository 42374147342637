/* eslint-disable no-else-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthUser, useSignOut } from 'react-auth-kit';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  CircularProgress,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  TablePagination,
  Divider,
  Link,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import { brokenToolsList, markToolRepairedApi } from '../utils/api';
// ----------------------------------------------------------------------

// let TOOL_LIST = [];

const TABLE_HEAD = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'serial', label: 'Serial', alignRight: false },
  { id: 'partNumber', label: 'Part no.', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'employee', label: 'Employee', alignRight: false },
  { id: 'view', label: 'View', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TaekiPage() {
  const [open, setOpen] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [toolList, setToolList] = useState([]);

  const [selected, setSelected] = useState([]);
  const [shouldReload, setShouldReload] = useState(false);

  const [orderBy, setOrderBy] = useState('id');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [currentTool, setCurrentTool] = useState(null);

  const [loading, setLoading] = useState(true); // New loading state
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const navigate = useNavigate();

  const auth = useAuthUser();
  const signOut = useSignOut();

  const { token } = auth();

  const fetchToolsList = async () => {
    try {
      const projects = await brokenToolsList(token, signOut);
      setToolList(projects);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error('Error fetching broken tools list:', error);
      setLoading(false); // Set loading to false in case of error as well
    }
  };

  useEffect(() => {
    fetchToolsList();
  }, []);

  useEffect(() => {
    if (shouldReload) {
      fetchToolsList();
      setShouldReload(false);
    }
  }, [shouldReload]);

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const markToolRepaired = async () => {
    setLoading(true);
    try {
      showSnackbar('Tool has been marked as repaired!', 'success');
      await markToolRepairedApi(token, signOut, currentTool.serial);
      setOpenEditModal(false);
      setShouldReload(true);
      setLoading(false);
    } catch (error) {
      console.error('Error marking tool as repaired:', error);
      showSnackbar('An error occurred while updating tool status.', 'error');
      setLoading(false);
    }
  }

  const handleViewTool = (toolId) => {
    // navigate(`/dashboard/tools/${toolId}`);
    const foundTool = toolList.find((tool) => tool.id === toolId);
    setCurrentTool(foundTool);
    setOpenEditModal(true);
  }

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({ ...snackbar, open: false });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - toolList.length) : 0;

  const filteredUsers = applySortFilter(toolList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  console.log('currentTool:', currentTool)
  return (
    <>
      <Helmet>
        <title> U\S | GMT ehf </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Broken Tools (U\S)
          </Typography>
        </Stack>
        {loading ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100%', minHeight: 300 }}
          >
            <CircularProgress color="primary" />
          </Stack>

        ) : (
          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={toolList.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    displayCheckbox={false}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, name, serial, partNumber, location, employee } = row;
                      const selectedUser = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          onClick={() => handleViewTool(id)}
                          selected={selectedUser}
                          sx={{
                            "&:hover": {
                              cursor: 'pointer',
                            },
                          }}
                        >
                          <TableCell align="left">{id}</TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          {/* Bætti við viðeigandi dálkum hér en Part no. og location ekki að birtist í Tool Inventory töflunni. */}
                          <TableCell align="left">{serial}</TableCell>
                          <TableCell align="left">{partNumber}</TableCell>
                          <TableCell align="left">{location}</TableCell>
                          <TableCell align="left">{employee}</TableCell>

                          <TableCell align="right">
                            <Button variant="contained" type="submit" onClick={() => handleViewTool(id)} startIcon={<Iconify icon="eva:eye-fill" />}>See Report</Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Nothing found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Check for spelling mistakes.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={toolList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>
      <Dialog
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        PaperProps={{
          sx: { width: 600, maxWidth: '100%', borderRadius: 2 },
        }}
      >
        <DialogTitle>Inspecting {currentTool?.name}</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Divider />
            <Typography variant="h6" gutterBottom>Tool Information</Typography>
            <Typography gutterBottom><b>ID:</b> {currentTool?.id}</Typography>
            <Typography gutterBottom><b>Name:</b> {currentTool?.name}</Typography>
            <Typography gutterBottom><b>Serial:</b> {currentTool?.serial}</Typography>
            <Typography gutterBottom><b>Part Number:</b> {currentTool?.partNumber}</Typography>
            <Typography gutterBottom><b>Visible Damage:</b> {currentTool?.isVisibleDamage ? "Yes" : "No"}</Typography>
            {currentTool?.brokenPhoto && currentTool?.brokenPhoto !== "" && (
              <Typography gutterBottom><b>Image:</b> <Link target="_blank" rel="noopener" href={currentTool?.brokenPhoto}>View Image</Link></Typography>
            )}
            <Divider />
            <Typography variant="h6" gutterBottom>Report</Typography>
            <Typography gutterBottom><b>Description:</b> {currentTool?.brokenInfo}</Typography>
            <Typography gutterBottom><b>Employee Name:</b> {currentTool?.employee}</Typography>
            <Typography gutterBottom><b>Employee Phone:</b> {currentTool?.employeePhone}</Typography>
            <Typography gutterBottom><b>Employee E-mail:</b> {currentTool?.employeeEmail}</Typography>
            <Typography gutterBottom><b>View Tool History:</b> <Button variant="outlined" onClick={() => navigate(`/dashboard/tools/${currentTool?.id}`)}>View</Button></Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpenEditModal(false)}>Cancel</Button>
          <Button variant="contained" onClick={() => markToolRepaired()}>Mark Repaired</Button>
        </DialogActions>
      </Dialog>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
