import { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Stack, Typography, CircularProgress, Card, Divider, CardContent, Chip, Box, FormControlLabel, FormGroup, Checkbox, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { red } from '@mui/material/colors';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAuthUser, useSignOut } from 'react-auth-kit'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getAllToolsRented } from '../utils/api';
import InOutPDFExport from './InOutPDFExport';
import Iconify from '../components/iconify';

export default function InOut() {
  const auth = useAuthUser();
  const signOut = useSignOut();
  const captureRef = useRef(null);
  const { token, id: employeeId } = auth();
  const { data: projectListData, isLoading, refetch } = useQuery('alltools', () => getAllToolsRented(token, signOut));
  console.log(projectListData);

  const [rentals, setRentals] = useState([]);
  const [generatePDF, setGeneratePDF] = useState(false);
  const [pdfData, setPdfData] = useState([{}]);
  const [isOpen, setIsOpen] = useState(false);



  const formatDate = (dateString) => {
    if (!dateString) return 'Not returned';
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  };

  // State for checkboxes
  const [filters, setFilters] = useState({
    in: true,
    out: true,
    KEF: true,
    AAl: true,
    BLL: true,
    HEL: true,
  });


  const today = new Date();
  const firstDayTwoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1);
  const endDate = new Date();

  // State for date range
  const [dateRange, setDateRange] = useState({
    start: firstDayTwoMonthsAgo,
    end: endDate,
  });

  // UseEffect to refetch data based on the default dateRange
  useEffect(() => {
    refetch();
  }, [dateRange, refetch]);

  // State for specific tool
  const [selectedTool, setSelectedTool] = useState('');
  const uniqueTools = Array.from(new Set(projectListData?.map(item => item.name)));


  // Handle change for checkboxes
  const handleFilterChange = (event) => {
    const { name, checked } = event.target;
    setFilters(prev => ({
      ...prev,
      [name]: checked,
    }));
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Function to handle PDF generation and redirect
  const handlePDFExport = async () => {
    setLoading(true);
    setGeneratePDF(true);
    setPdfData(filteredData);
    setLoading(false);
    navigate('/dashboard/in-out');
  };

  const filteredData = projectListData?.filter((item) => {
    const itemStartDate = item.rentStartDate ? new Date(item.rentStartDate) : null;
    const itemEndDate = item.rentEndDate ? new Date(item.rentEndDate) : null;
    const toolFilter = selectedTool ? item.name === selectedTool : true;

    const statusFilter = (filters.in && item.isReturned) ||
      (filters.out && !item.isReturned && itemStartDate >= dateRange.start && itemStartDate <= dateRange.end); const addressFilter = ["KEF", "AAl", "BLL", "HEL"].some(address => filters[address] && item.customerAddress.includes(address));

    // Apply date filter only if both start and end dates are selected
    const dateFilter = (!dateRange.start || !dateRange.end) ||
      (itemStartDate && itemEndDate && itemStartDate >= dateRange.start && itemEndDate <= dateRange.end);

    return statusFilter && addressFilter && dateFilter && toolFilter;
  });

  if (isLoading || loading) {
    return (
      <>
        <Helmet>
          <title> Loading... | GMT ehf </title>
        </Helmet>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Loading...
            </Typography>
          </Stack>
          <Card>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: '100%', minHeight: 300 }}
            >
              <CircularProgress color="primary" />
            </Stack>
          </Card>
        </Container>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>In/Out | GMT ehf</title>
      </Helmet>
      <Container>
        <Stack direction="column" spacing={2} mb={5}>
          <Typography variant="h4" gutterBottom>
            Tools In/Out
          </Typography>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={filters.in} onChange={handleFilterChange} name="in" />}
              label="In"
            />
            <FormControlLabel
              control={<Checkbox checked={filters.out} onChange={handleFilterChange} name="out" />}
              label="Out"
            />
            <FormControlLabel
              control={<Checkbox checked={filters.KEF} onChange={handleFilterChange} name="KEF" />}
              label="KEF"
            />
            <FormControlLabel
              control={<Checkbox checked={filters.AAl} onChange={handleFilterChange} name="AAl" />}
              label="AAl"
            />
            <FormControlLabel
              control={<Checkbox checked={filters.BLL} onChange={handleFilterChange} name="BLL" />}
              label="BLL"
            />
            <FormControlLabel
              control={<Checkbox checked={filters.HEL} onChange={handleFilterChange} name="HEL" />}
              label="HEL"
            />
          </FormGroup>
          <Stack direction="row" spacing={2}>
            <DatePicker
              style={{ marginBottom: 20, zIndex: isOpen ? 9999 : 1, position: 'relative' }}
              selected={dateRange.start}
              onFocus={() => setIsOpen(true)}
              onBlur={() => setIsOpen(false)}
              onChange={(date) => setDateRange({ ...dateRange, start: date })}
              selectsStart
              startDate={dateRange.start}
              endDate={dateRange.end}
              isClearable
              placeholderText="Select start date"
            />
            <DatePicker
              style={{ marginBottom: 20, zIndex: isOpen ? 9999 : 1, position: 'relative' }}
              selected={dateRange.end}
              onFocus={() => setIsOpen(true)}
              onBlur={() => setIsOpen(false)}
              onChange={(date) => setDateRange({ ...dateRange, end: date })}
              selectsEnd
              startDate={dateRange.start}
              endDate={dateRange.end}
              minDate={dateRange.start}
              isClearable
              placeholderText="Select end date"
            />
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:download-fill" />}
              onClick={handlePDFExport}
            >
              Export PDF
            </Button>
          </Stack>

          <FormControl fullWidth sx={{ width: '50%' }}>
            <InputLabel id="tool-select-label">Tool</InputLabel>
            <Select
              labelId="tool-select-label"
              value={selectedTool}
              label="Tool"
              onChange={(e) => setSelectedTool(e.target.value)}
              renderValue={selectedTool !== "" ? undefined : () => <em> </em>}
              displayEmpty
            >
              <MenuItem value="">
                <em> </em>
              </MenuItem>
              {uniqueTools.map((tool) => (
                <MenuItem key={tool} value={tool}>{tool}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <div ref={captureRef}>
          <Stack spacing={1} sx={{ marginTop: 3 }}>
            {filteredData && filteredData.map((item, index) => (
              <Card variant="outlined" key={index} sx={{ width: '100%' }}>
                <CardContent>
                  <Stack justifyContent="space-between" direction="row">
                    {/* First Section: Tool's Name and Serial */}
                    <Stack sx={{ minWidth: 250 }}>
                      <Typography variant="subtitle1" gutterBottom>{item?.name}</Typography>
                      <Typography variant="body2" color="text.secondary">Serial: {item?.serial}</Typography>
                    </Stack>

                    {/* Divider */}
                    <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

                    {/* Second Section: Customer Address and Employee Name */}
                    <Stack sx={{ minWidth: 250 }}>
                      <Typography variant="body2" color="text.secondary">Customer Address: {item?.customerAddress}</Typography>
                      <Typography variant="body2" color="text.secondary">Employee: {item?.employeeFirstName} {item?.employeeLastName}</Typography>
                    </Stack>

                    {/* Divider */}
                    <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

                    {/* Third Section: Rental Start and End Dates */}
                    <Stack sx={{ minWidth: 250 }} spacing={1}>
                      <Typography variant="body2" color="text.secondary">Out: {formatDate(item?.rentStartDate)}</Typography>
                      <Typography variant="body2" style={{ color: item?.rentEndDate ? 'inherit' : red[500] }}>
                        In: {item?.isReturned ? formatDate(item?.rentEndDate) : 'N/a'}
                      </Typography>
                      {/* Conditionally rendered Chip based on whether item has been returned */}
                      <Box display="flex" justifyContent="flex-start">
                        {item?.isReturned ? (
                          <Chip size="small" color="success" label="RETURNED" />
                        ) : (
                          <Chip size="small" color="error" label="OUT" />
                        )}
                      </Box>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </div>
      </Container >
      {generatePDF && <InOutPDFExport filteredData={pdfData} />}
    </>
  );
}



