import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuthUser, useSignOut } from 'react-auth-kit';

import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  Alert,
  CircularProgress,
} from '@mui/material';

// sections
import { useForm } from 'react-hook-form';
import { changePasswordEmployee } from '../utils/api';

export default function AccountSettings() {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const auth = useAuthUser();
  const signOut = useSignOut();

  const { token } = auth();

  const resetMessages = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };

  const onSubmit = async (data) => {
    resetMessages();
    setIsLoading(true);

    const { password, passwordTwo } = data;

    if (password !== passwordTwo) {
      setErrorMessage('The passwords do not match!');
      setIsLoading(false);
      return;
    }

    try {
      await changePasswordEmployee(password, token, signOut);
      setSuccessMessage('The password has been changed!');
      reset();
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title> Settings | GMT ehf </title>
      </Helmet>

      <Container maxWidth="lg">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Settings
          </Typography>
        </Stack>
        <Card>
          {successMessage && (
            <Alert severity="success">
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="error">
              {errorMessage}
            </Alert>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} sx={{ p: 3 }}>
              <Typography>Reset Password</Typography>
              <Stack direction="row" spacing={2}>
                <TextField
                  {...register('password', { required: true })}
                  name="password"
                  label="New password"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.password}
                  helperText={errors.password && 'Type in a new password'}
                />
              </Stack>
              <Stack direction="column" spacing={0}>
                <TextField
                  {...register('passwordTwo', { required: false })}
                  name="passwordTwo"
                  label="Confirm new password"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.passwordTwo}
                  helperText={errors.passwordTwo && 'Type in the new password again'}
                />
              </Stack>
            </Stack>
            <br /><br />
            {isLoading ? ( // Render the circular progress bar if loading is true
              <Button
                variant="contained"
                disabled
                startIcon={<CircularProgress size={20} />} // Add the circular progress icon
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  margin: '10px',
                }}
              >
                Stofna
              </Button>
            ) : (
              <Button
                variant="contained"
                type="submit"
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  margin: '10px',
                }}
              >
                Send
              </Button>
            )}
          </form>
        </Card>
      </Container>
    </>
  );
}
