/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useAuthUser, useSignOut } from 'react-auth-kit'
import {
  Card,
  CardContent,
  CardActions,
  Stack,
  Chip,
  Container,
  Typography,
  ListItemButton,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Box,
  TextField,
  Badge,
  Button,
  Alert,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';

import { useParams } from 'react-router-dom';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddIcon from '@mui/icons-material/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import PDFVerkefni from './PDFExport';

import { getProjectDetails, closeProject, openProject, billProject, stopRent, toolsList, addNewTool, editComment } from '../utils/api';

export default function VerkefniViewPage() {
  const { id } = useParams();
  const [project, setProject] = useState(null); // Add state to hold the project data
  const [isLoading, setIsLoading] = useState(true); // Add state to control the loading state
  const [openModal, setOpenModal] = useState(false);
  const [editedComments, setEditedComments] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [exportProject, setExportProject] = useState(false); // Add state to control exporting the project
  const [newRental, setNewRental] = useState('');
  const [toolList, setToolList] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const auth = useAuthUser();
  const signOut = useSignOut();
  const handleOpenConfirmDialog = () => setOpenConfirmDialog(true);
  const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);

  const { token, id: employeeId } = auth();

  const handleOpenModal = () => {
    setOpenModal(true);
    setEditedComments(project.information); // Set the initial value of the edited comments
  };

  const handleCheckTools = () =>
    // check if all tools have isReturned set to true
    project.tools.every(tool => tool.isReturned)
    ;

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCommentEdit = async () => {
    // Call your desired logic to update the comments
    // For example, you can update the project state or make an API call
    console.log('Edited comments:', editedComments);
    handleCloseModal();
    project.information = editedComments;
    const response = await editComment(editedComments, parseInt(id, 10), token, signOut);

  };

  const handleCloseProject = async () => {
    if (!handleCheckTools()) {
      setErrorMessage("There are still tools registered to this project. Please return the tools first.");
      return;
    }
    handleOpenConfirmDialog();
  };

  const confirmAndCloseProject = async () => {
    setIsLoading(true);
    try {
      await closeProject(id, token, signOut);
      fetchProjectDetails();
    } catch (error) {
      console.error('Error closing project:', error);
    }
    setIsLoading(false);
    handleCloseConfirmDialog();
  };

  const handleOpenProject = async () => {
    setIsLoading(true);
    try {
      await openProject(id, token, signOut);
      fetchProjectDetails();
      // Add a success message
    } catch (error) {
      console.error('Error open project:', error);
      // Add an error message
    }
    setIsLoading(false);
  };

  const handleAutocompleteChange = (event, newValue) => {
    setNewRental(newValue);
  };

  const onAddNewTool = async () => {
    if (newRental.name && newRental.serial) {
      const newTool = await addNewTool(newRental.name, newRental.serial, id, employeeId, project.customerId, token, signOut);
      if (newTool) {
        fetchProjectDetails();
      }
    }
  }

  const handleBilledProject = async () => {
    if (!handleCheckTools()) {
      setErrorMessage("There are still tools registered to this project. Please return the tools first.");

      return;
    }
    setIsLoading(true);
    try {
      await billProject(id, token, signOut);
      fetchProjectDetails();
      // Add a success message
    } catch (error) {
      console.error('Error closing project:', error);
      // Add an error message
    }
    setIsLoading(false);
  };

  const handleExportProject = async () => {
    try {
      setExportProject(true); // Set the flag to true to indicate exporting the project
    } catch (error) {
      console.error('Error closing project:', error);
      // Add an error message
    }
  };

  // function formatDate(dateString) {
  //   const date = new Date(dateString); // Convert the date string to a Date object
  //   const options = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };
  //   const formattedDate = date.toLocaleDateString('en-GB', options);
  //   return formattedDate;
  // }

  // function that calculates first 24 hours and displays them but then when full day has been reached it counts days. If rentEndDate is null then it uses current date else it uses rentEndDate
  function formatCustomDate(rentStartDate, rentEndDate) {
    const startDate = new Date(rentStartDate);
    const endDate = rentEndDate ? new Date(rentEndDate) : new Date(); // Use current date if rentEndDate is null
    const diffTime = Math.abs(endDate - startDate);
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60)); // Calculate difference in hours
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Calculate difference in days

    // If the tool hasn't been returned (rentEndDate is null) or if the difference is less than 24 hours, show hours
    if (!rentEndDate || diffHours < 24) {
      return `${diffHours} hour${diffHours !== 1 ? 's' : ''}`; // Handle pluralization for hours
    }
    // If the difference is 24 hours or more, show days
    return `${diffDays} day${diffDays !== 1 ? 's' : ''}`; // Handle pluralization for days

  }


  const fetchProjectDetails = async () => {
    setIsLoading(true);
    try {
      const projectData = await getProjectDetails(id, token, signOut);
      setProject(projectData);
      const tools = await toolsList(token, signOut);
      setToolList(tools);
    } catch (error) {
      console.error('Error fetching project details:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchProjectDetails();
  }, [id]);

  const handleToolClick = async (item) => {
    try {
      console.log("Stop rent tool")
      await stopRent(item.id, token, signOut);
      // Add a success message
      // Then fetch project details again
      await fetchProjectDetails();
    } catch (error) {
      console.error('Error removing tool:', error);
      // Add an error message
    }
  };

  const viewPhoto = (photo) => {
    window.open(photo, '_blank', 'noopener,noreferrer')
  }

  if (exportProject) {
    return <PDFVerkefni project={project} />; // Render the PDFVerkefni component with the project data
  }

  if (isLoading) {
    // Add a loading state
    // console.log('Loading project:', project)
    return (
      <>
        <Helmet>
          <title> View Project | GMT ehf </title>
        </Helmet>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              View Project
            </Typography>
          </Stack>
          <Card>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ height: '100%', minHeight: 300 }}
            >
              <CircularProgress color="primary" />
            </Stack>
          </Card>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title> View Project | GMT ehf </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            View Project
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2} mb={2}>
          {project && project.projectStatus && (
            <Button startIcon={<LockIcon />} color="error" variant="contained" onClick={handleCloseProject}>
              Close Project
            </Button>
          )}
          {project && !project.projectStatus && (
            <Button startIcon={<PictureAsPdfIcon />} variant="contained" onClick={handleExportProject}>
              Download PDF
            </Button>
          )}
          {project && !project.isBilled && !project.projectStatus && (
            <Button startIcon={<AttachMoneyIcon />} color="error" variant="contained" onClick={handleBilledProject}>
              Mark as Billed
            </Button>
          )}
          {project && !project.isBilled && !project.projectStatus && (
            <Button startIcon={<LockOpenIcon />} color="success" variant="contained" onClick={handleOpenProject}>
              Open Project
            </Button>
          )}
        </Stack>

        <Card>
          {errorMessage && (
            <Alert severity="error">
              {errorMessage}
            </Alert>
          )}
          <Grid container spacing={2} sx={{ padding: '24px', alignItems: 'flex-start' }}>
            <Grid item xs={12} md={12}>
              <Badge sx={{ marginTop: '10px', marginBottom: '20px' }} badgeContent={project && project.projectStatus ? 'OPEN' : 'CLOSED'} color={project && project.projectStatus ? 'success' : 'error'}>
                <Chip
                  size="large"
                  label={project.customerAddress}
                  sx={{
                    padding: '15px',
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  }}
                />
              </Badge>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography><b>Project:</b> {project.customerName}</Typography>
              {/* <Typography><b>Phone:</b> {project.customerPhone}</Typography> */}
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography><b>Employee:</b> {project.employeeFirstName} {project.employeeLastName}</Typography>
              <Typography><b>Date:</b> {new Date(project.projectStartDate).toLocaleDateString('en-GB')}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <b>Comments</b> <Typography style={{ whiteSpace: 'pre-line' }} onClick={handleOpenModal}>{project.information}</Typography>
              <Divider sx={{ marginTop: 3 }} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="subtitle1">Tools</Typography>
              <Stack spacing={1} sx={{ marginTop: 3 }}>
                {project && project.tools && project.tools.map((item, index) => (
                  <Card variant="outlined" key={index} sx={{ width: '100%' }}>
                    <CardContent>
                      <Stack justifyContent="space-between" direction="row" divider={<Divider orientation="vertical" flexItem />}>
                        <Stack sx={{ minWidth: 250 }}>
                          <Stack direction="row">
                            <Typography variant="subtitle1" gutterBottom>{item?.name}</Typography>
                          </Stack>
                          <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                            <Typography variant="body2" color="text.secondary">Serial: {item?.serial}</Typography>
                            <Typography variant="body2" color="text.secondary">Part #: {item?.partNumber}</Typography>
                          </Stack>
                          <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                            <Typography variant="body2" color="text.secondary">{formatCustomDate(item?.rentStartDate, item?.rentEndDate)}</Typography>
                            {/* <Typography variant="body2" color="text.secondary">Return: {formatDate(item?.rentEndDate)}</Typography> */}
                          </Stack>
                        </Stack>
                        <Stack spacing={1} sx={{ minWidth: 250 }} divider={<Divider orientation="vertical" flexItem />}>
                          <Stack>
                            <Stack direction="row">
                              <Typography variant="subtitle1" gutterBottom>Employee</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                              <Typography variant="body2" color="text.secondary">{item?.employeeFirstName} {item?.employeeLastName}</Typography>
                              <Typography variant="body2" color="text.secondary"><a href={`mailto:${item?.employeeEmail}`}>{item?.employeeEmail}</a></Typography>
                              <Typography variant="body2" color="text.secondary"><a href={`tel:${item?.employeePhone}`}>{item?.employeePhone}</a></Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                        <Stack spacing={1} sx={{ minWidth: 250 }} alignItems="center" justifyContent="center">
                          {(!item.photoBefore && !item.photoAfter) && (
                            !item.isPhotoRequired ? (
                              <Typography color='text.secondary' variant="body2">This tool doesn't require photos..</Typography>
                            ) : (
                              <Typography color='text.secondary' variant="body2">No photos yet..</Typography>
                            )
                          )}
                          {(item.isPhotoRequired && item.photoBefore && item.photoBefore !== "") && (
                            <Button variant='outlined' size="small" color="primary" onClick={() => viewPhoto(item.photoBefore)} sx={{ width: '60%' }}>View Photo Before</Button>
                          )}
                          {(item.isPhotoRequired && item.photoAfter && item.photoAfter !== "") && (
                            <Button variant='outlined' size="small" color="primary" onClick={() => viewPhoto(item.photoAfter)} sx={{ width: '60%' }}>View Photo After</Button>
                          )}
                        </Stack>
                      </Stack>
                    </CardContent>
                    <Divider flexItem />
                    <CardActions style={{ backgroundColor: 'rgba(200,200,200,0.09)' }} sx={{ paddingLeft: 3, paddingRight: 3 }}>
                      <Stack justifyContent="space-between" alignItems="center" direction="row" spacing={3} sx={{ width: '100%' }}>
                        <Chip variant="filled" size="small" color={item.isReturned ? 'success' : 'primary'} label={item.isReturned ? 'RETURNED' : 'IN USE'} />
                        {!item.isReturned && (
                          <Button variant='outlined' size="small" color="error" onClick={() => handleToolClick(item)}>Return Tool</Button>
                        )}
                      </Stack>
                    </CardActions>
                  </Card>
                ))}
              </Stack>
            </Grid>
            {/* // <Grid item xs={1} md={1}>
            //   <Divider orientation="vertical" sx={{ height: '300px' }} />
            // </Grid>
            // <Grid item xs={4} md={4}>
            //   {project && project.projectStatus && (
            //     <>
            //       <Box sx={{ margin: "10px", display: 'flex', justifyContent: 'flex-end' }}>
            //         <Typography variant="subtitle1">Add Tool</Typography>
            //       </Box>
            //       <Box sx={{ margin: "10px", display: 'flex', flexDirection: 'row-reverse' }}>
            //         <Autocomplete
            //           id="taeki-select"
            //           options={toolList}
            //           sx={{ width: 300 }}
            //           onChange={handleAutocompleteChange}
            //           getOptionLabel={(option) => `${option.name} - ${option.serial}`}
            //           renderInput={(params) => <TextField {...params} label="Tool - Serial No." />} />
            //       </Box>
            //       <Box sx={{ margin: "10px", display: 'flex', flexDirection: 'row-reverse' }}>
            //         <Button sx={{ marginTop: "10px" }} startIcon={<AddIcon />} color="primary" variant="outlined" onClick={onAddNewTool}>
            //           Send
            //         </Button>
            //       </Box>
            //     </>
            //   )}
            // </Grid> */}
          </Grid>
        </Card>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              border: 'none',
              outline: 'none',
              boxShadow: 24,
              p: 4,
            }}
          >
            <TextField
              label="Edit Comment"
              multiline
              rows={4}
              value={editedComments}
              onChange={(event) => setEditedComments(event.target.value)}
              sx={{ marginBottom: '16px', width: '600px' }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
              <Button onClick={handleCommentEdit} variant="contained">Edit</Button>
            </Box>
          </Box>
        </Modal>
      </Container >
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Are you sure you want to close ${project.customerName}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Before signing off the certificate of release to service, certifying staff shall ensure that after completion of maintenance a general verification is carried out to ensure that the aircraft or component is clear of all tools, equipment and any extraneous parts or material, all access panels removed have been refitted. MOE 2.24.5
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            No
          </Button>
          <Button onClick={confirmAndCloseProject} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
