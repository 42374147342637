import { useState } from 'react';
import { useSignIn } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom';
import { Stack, IconButton, InputAdornment, TextField, Checkbox, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import API_URL from '../../../variables';

async function loginAPI(kennitala, password) {
  const response = await fetch(`${API_URL}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ kennitala, password }),
  });

  const data = await response.json();
  if (response.ok) {
    return data.token;
  }

  throw new Error(data.message || 'Login failed');
}

async function userDetails(token, kennitala) {
  // console.log("Sending",token, kennitala)
  const response = await fetch(`${API_URL}/login/details/${kennitala}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
    }
  });

  const data = await response.json();
  if (response.ok) {
    return data;
  }

  throw new Error(data.error || 'Details failed');
}

export default function LoginForm() {
  const navigate = useNavigate();
  const signIn = useSignIn();
  const [showPassword, setShowPassword] = useState(false);
  const [kennitala, setKennitala] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClick = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const token = await loginAPI(kennitala, password);
      const details = await userDetails(token, kennitala);
      if (signIn(
        {
          token,
          expiresIn: 10080,
          tokenType: "Bearer",
          authState: {
            token,
            name: `${details.firstName} ${details.lastName}`,
            email: details.email,
            kennitala: details.kennitala,
            firstName: details.firstName,
            lastName: details.lastName,
            phone: details.phone,
            id: details.id,
            isAdmin: details.isAdmin,
          },
        }
      ))

        if (details && details.isAdmin === true) {
          navigate('/dashboard', { replace: true });
        }
        else {
          navigate('/logout');
        }

    } catch (error) {
      setError(error.message);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="kennitala"
          label="Employee Number"
          value={kennitala}
          onChange={(e) => setKennitala(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((prev) => !prev)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Muna eftir mér" />
        Remember me
      </Stack>

      {error && (
        <Alert severity="error">{error}</Alert>
      )}

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
        loading={isLoading}
      >
        Login
      </LoadingButton>
    </>
  );
}
