/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useAuthUser, useSignOut } from 'react-auth-kit';
import {
  Card,
  Stack,
  Button,
  MenuItem,
  Container,
  Typography,
  Divider,
  TextField,
  Alert
} from '@mui/material';

import { useForm } from 'react-hook-form';
import Iconify from '../components/iconify';
import { createEmployee } from '../utils/api';

export default function UserEditPage() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [isAdmin, setIsAdmin] = useState(false); // Update the initial state to false
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const resetMessages = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };

  const auth = useAuthUser();
  const signOut = useSignOut();

  const { token } = auth();

  const onSubmit = async (data) => {
    resetMessages();
    const { newFirstName, newLastName, newKennitala, newPassword, newPhone, newEmail } = data;
    const fullName = `${newFirstName} ${newLastName}`;
    const firstName = newFirstName;
    const lastName = newLastName;
    const kennitala = newKennitala;
    const password = newPassword;
    const phone = newPhone;
    const email = newEmail;
    const admin = isAdmin;

    try {
      await createEmployee(firstName, lastName, kennitala, password, phone, email, admin, token, signOut);
      setSuccessMessage('Employee has been added successfully!');
    } catch (error) {
      setErrorMessage(error.message || 'Error! Check that all details are entered correctly and make sure an employee with the same email or id does not exist.');
    }
  };

  return (
    <>
      <Helmet>
        <title> New Employee | GMT ehf </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            New Employee
          </Typography>
        </Stack>
        <Card>
          {successMessage && (
            <Alert severity="success">
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="error">
              {errorMessage}
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} sx={{ p: 3 }}>
              <Stack direction="row" spacing={2}>
                <TextField
                  {...register('newFirstName', { required: true })}
                  name="newFirstName"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newFirstName}
                  helperText={errors.newFirstName && 'Please enter a first name'}
                />
                <TextField
                  {...register('newLastName', { required: true })}
                  name="newLastName"
                  label="Eftirnafn"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newLastName}
                  helperText={errors.newLastName && 'Please enter a last name'}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  {...register('newKennitala', { required: true, maxLength: 6 })}
                  name="newKennitala"
                  label="Employee Number"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newKennitala}
                  helperText={errors.newKennitala && 'Please enter a valid employee number'}
                />
                <TextField
                  {...register('newPassword', { required: true })}
                  name="newPassword"
                  label="Lykilorð"
                  variant="outlined"
                  type="password"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newPassword}
                  helperText={errors.newPassword && 'Please enter a password'}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  {...register('newPhone', { required: true, maxLength: 7, pattern: /[0-9]{7}/ })}
                  name="newPhone"
                  label="Símanúmer"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.newPhone}
                  helperText={errors.newPhone && 'Please enter a phone number'}
                />
                <TextField
                  {...register('newEmail', { required: true, pattern: /^\S+@\S+$/i })}
                  name="newEmail"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  autoComplete="off"
                />
              </Stack>

              <Divider sx={{ my: 3 }}>Admin?</Divider>

              <TextField
                {...register('isAdmin')}
                name="isAdmin"
                variant="outlined"
                select
                fullWidth
                SelectProps={{
                  displayEmpty: true,
                  defaultValue: 'false' // Set the default value to "false"
                }}
                value={isAdmin}
                onChange={(e) => setIsAdmin(e.target.value === 'true')} // Update the value to boolean
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>
              {errors.isAdmin && <span>You forgot to select here!</span>}
            </Stack>
            <br /><br />
            <Button
              variant="contained"
              type="submit"
              startIcon={<Iconify icon="eva:plus-fill" />}
              sx={{
                position: 'absolute',
                bottom: '10px',
                right: '10px',
                margin: '10px',
              }}
            >
              Create
            </Button>
          </form>
        </Card>
      </Container>
    </>
  );
}
