/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthUser, useSignOut } from 'react-auth-kit';

// @mui
import {
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  CircularProgress,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@mui/material';
// components
import { useQuery } from 'react-query';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ToolHistoryPDFExport from './ToolHistoryPDFExport';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import { projectList, toolDetails } from '../utils/api';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'customerId', label: '#', alignRight: false },
  { id: 'customerName', label: 'Customer name', alignRight: false },
  { id: 'customerAddress', label: 'Location', alignRight: false },
  // { id: 'customerPhone', label: 'Phone', alignRight: false },
  { id: 'employeeName', label: 'Employee', alignRight: false },
  { id: 'dateIn', label: 'Date(In)', alignRight: false },
  { id: 'dateOut', label: 'Date(Out)', alignRight: false },
  { id: 'view', label: 'View project', alignRight: true }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, filters) {

  // apply location filter

  const filteredByLocation = array.filter((project) => {
    if (filters.KEF && project.customerAddress.includes('KEF')) return true;
    if (filters.AAl && project.customerAddress.includes('AAl')) return true;
    if (filters.BLL && project.customerAddress.includes('BLL')) return true;
    if (filters.HEL && project.customerAddress.includes('HEL')) return true;
    if (project.customerAddress === '') return true;
    return false;
  });

  // Apply text filter (by query) on customer address
  const filteredByQuery = filteredByLocation.filter((project) =>
    project.customerName.toLowerCase().includes(query.toLowerCase())
  );

  // Apply sorting
  const sortedProjects = filteredByQuery.sort(comparator);

  return sortedProjects;
}

export default function TaekiViewPage() {
  const { toolSpecificId } = useParams();
  const auth = useAuthUser();
  const signOut = useSignOut();
  const { token } = auth();

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('customerId');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [PROJECT_LIST, setProjectList] = useState([]); // New project list state
  const [generatePDF, setGeneratePDF] = useState(false);
  const [pdfData, setPdfData] = useState([{}]);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { data: projectsData, isLoading, refetch } = useQuery('toolhistory', () => toolDetails(toolSpecificId, token, signOut));

  const [filters, setFilters] = useState({
    KEF: true,
    AAl: true,
    BLL: true,
    HEL: true,
  });

  useEffect(() => {
    if (projectsData) {
      setProjectList(projectsData);
    }
  }, [projectsData]);

  // Get today's date
  const today = new Date();
  // Calculate the first day of the month, two months ago
  const firstDayTwoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1);
  // Today's date is used for the end date
  const endDate = new Date();

  // State for date range
  const [dateRange, setDateRange] = useState({
    start: firstDayTwoMonthsAgo,
    end: endDate,
  });

  const handleViewTool = (projectId) => {
    navigate(`/dashboard/project/${projectId}`);
  }

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterChange = (event) => {
    const { name, checked } = event.target;
    setFilters(prev => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - PROJECT_LIST.length) : 0;

  const filteredUsers = applySortFilter(PROJECT_LIST, getComparator(order, orderBy), filterName, filters);

  const isNotFound = !filteredUsers.length && !!filterName;

  const handlePDFExport = async () => {
    setGeneratePDF(true);
    setPdfData(filteredUsers);
    // navigate('/dashboard/closed');
  };


  return (
    <>
      <Helmet>
        <title> Tool History | GMT ehf </title>
      </Helmet>

      <Container>
        {isLoading ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100%', minHeight: 300 }}
          >
            <CircularProgress color="primary" />
          </Stack>
        ) : (
          <Container>
            <Typography variant="h4" sx={{ m: 5 }}>Tool History</Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={5}>
              <Stack>
                <FormGroup row>
                  <FormControlLabel
                    control={<Checkbox checked={filters.KEF} onChange={handleFilterChange} name="KEF" />}
                    label="KEF"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={filters.AAl} onChange={handleFilterChange} name="AAl" />}
                    label="AAl"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={filters.BLL} onChange={handleFilterChange} name="BLL" />}
                    label="BLL"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={filters.HEL} onChange={handleFilterChange} name="HEL" />}
                    label="HEL"
                  />
                </FormGroup>
                <Stack direction="row" spacing={2}>
                  <DatePicker
                    style={{ marginBottom: 20, zIndex: isOpen ? 9999 : 1, position: 'relative' }}
                    selected={dateRange.start}
                    onFocus={() => setIsOpen(true)}
                    onBlur={() => setIsOpen(false)}
                    onChange={(date) => setDateRange({ ...dateRange, start: date })}
                    selectsStart
                    startDate={dateRange.start}
                    endDate={dateRange.end}
                    isClearable
                    placeholderText="Select start date"
                  />
                  <DatePicker
                    style={{ marginBottom: 20, zIndex: isOpen ? 9999 : 1, position: 'relative' }}
                    selected={dateRange.end}
                    onFocus={() => setIsOpen(true)}
                    onBlur={() => setIsOpen(false)}
                    onChange={(date) => setDateRange({ ...dateRange, end: date })}
                    selectsEnd
                    startDate={dateRange.start}
                    endDate={dateRange.end}
                    minDate={dateRange.start}
                    isClearable
                    placeholderText="Select end date"
                  />
                  <Button
                    variant="contained"
                    startIcon={<Iconify icon="eva:download-fill" />}
                    onClick={handlePDFExport} // Corrected onClick handler syntax
                  >
                    Export PDF
                  </Button>
                </Stack>
              </Stack>
            </Stack>




            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={PROJECT_LIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    displayCheckbox={false}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { customerId, customerName, customerAddress, customerPhone, employeeName, startDate, returnDate } = row;
                      const selectedUser = selected.indexOf(customerName) !== -1;
                      const formattedStartDate = new Date(startDate).toLocaleDateString('en-GB');
                      const formattedReturnDate = new Date(returnDate).toLocaleDateString('en-GB');
                      return (
                        <TableRow
                          hover
                          key={customerId}
                          tabIndex={-1}
                          role="checkbox"
                          onClick={() => handleViewTool(customerId)}
                          selected={selectedUser}
                          sx={{
                            "&:hover": {
                              cursor: 'pointer',
                            },
                          }}
                        >
                          <TableCell align="left">{customerId}</TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {customerName}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{customerAddress}</TableCell>

                          {/* <TableCell align="left">{customerPhone}</TableCell> */}

                          <TableCell align="left">{employeeName}</TableCell>

                          <TableCell align="left">{formattedStartDate}</TableCell>

                          <TableCell align="left">{formattedReturnDate}</TableCell>

                          <TableCell align="right">
                            <Button variant="contained" type="submit" onClick={() => handleViewTool(customerId)} startIcon={<Iconify icon="eva:eye-fill" />}>View</Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {
                    isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Nothing found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Check for spelling mistakes.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )
                  }
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={PROJECT_LIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Container>
        )}
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      {generatePDF && <ToolHistoryPDFExport filteredData={pdfData} />}
    </>
  );
}
