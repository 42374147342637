import API_URL from '../variables';

async function fetchWrapper(endpoint, options = {}, token, signOut) {
  // Add the token to the Authorization header
  options.headers = {
    ...options.headers,
    'Content-Type': 'application/json',
    'Authorization': `${token}`
  };

  const response = await fetch(`${API_URL}${endpoint}`, options);

  // Handle 403 status by signing out
  if (response.status === 403) {
    signOut();
  }

  const data = await response.json();
  if (response.ok) {
    return data;
  }

  throw new Error(data.error || 'Request failed');
}

async function projectList(token, signOut) {
  try {
    const data = await fetchWrapper(`/rentals`, { method: 'GET' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Details failed');
  }
}

async function closedProjectList(token, signOut) {
  try {
    const data = await fetchWrapper(`/rentals/closed`, { method: 'GET' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Fetching closed project list failed');
  }
}

async function getProjectDetails(projectId, token, signOut) {
  try {
    const data = await fetchWrapper(`/rentals/${projectId}`, { method: 'GET' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Fetching project details failed');
  }
}

async function getAllRentals(token, signOut) {
  try {
    const data = await fetchWrapper('/rentals', { method: 'GET' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Fetching all rentals failed');
  }
}


async function billProject(id, token, signOut) {
  try {
    return await fetchWrapper(`/rentals/billed/${id}`, { method: 'PATCH' }, token, signOut);
  } catch (error) {
    console.error(error);
    throw new Error('Close project failed');
  }
}

async function closeProject(id, token, signOut) {
  try {
    return await fetchWrapper(`/rentals/${id}/close`, { method: 'PATCH' }, token, signOut);
  } catch (error) {
    console.error(error);
    throw new Error('Close project failed');
  }
}

async function openProject(id, token, signOut) {
  try {
    return await fetchWrapper(`/rentals/${id}/open`, { method: 'PATCH' }, token, signOut);
  } catch (error) {
    console.error(error);
    throw new Error('Open project failed');
  }
}

async function stopRent(id, token, signOut) {
  try {
    return await fetchWrapper(`/tools/${id}`, { method: 'DELETE' }, token, signOut);
  } catch (error) {
    console.error(error);
    throw new Error('Close project failed');
  }
}

async function createEmployee(firstName, lastName, kennitala, password, phone, email, locations, isAdmin, token, signOut) {
  try {
    const data = await fetchWrapper(`/signup`, {
      method: 'POST',
      body: JSON.stringify({
        firstName,
        lastName,
        kennitala,
        password,
        phone,
        email,
        locations,
        isAdmin,
      }),
    }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Edit user failed');
  }
}

async function createTool(newToolName, toolLocation, toolPhotoRequired, toolSerial, toolPartNr, token, signOut) {
  try {
    const data = await fetchWrapper(`/tools`, {
      method: 'POST',
      body: JSON.stringify({
        newToolName,
        toolLocation,
        toolPhotoRequired,
        toolSerial,
        toolPartNr,
      }),
    }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Adding new tool failed');
  }
}

async function employeeList(token, signOut) {
  try {
    const data = await fetchWrapper(`/employee`, { method: 'GET' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Details failed');
  }
}

async function updateEmployee(id, name, kennitala, phone, email, token, signOut) {
  try {
    const data = await fetchWrapper(`/employee`, {
      method: 'POST',
      body: JSON.stringify({
        id,
        name,
        email,
        kennitala,
        phone,
      }),
    }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Updating failed');
  }
}

async function deleteEmployee(id, token, signOut) {
  try {
    const data = await fetchWrapper(`/employee/${id}`, { method: 'DELETE' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Delete user failed');
  }
}

async function logoutEmployee(id, token, signOut) {
  try {
    const data = await fetchWrapper(`/login/logout/${id}`, { method: 'PATCH' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Logout user failed');
  }
}

async function resetPasswordEmployee(id, token, signOut) {
  try {
    const data = await fetchWrapper(`/email`, { method: 'POST', body: JSON.stringify({ userId: id }) }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Reset password failed');
  }
}

async function markToolRepairedApi(token, signOut, id) {
  try {
    const data = await fetchWrapper(`/tools/broken`, { method: 'POST', body: JSON.stringify({ toolId: id, isBroken: false }) }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    console.log(error);
    throw new Error('Marking tool repaired failed');
  }
}

async function sendOneSignalNotification(title, url, body, token, signOut) {
  try {
    const data = await fetchWrapper(`/onesignal`, {
      method: 'POST',
      body: JSON.stringify({ title, url, body })
    }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Sending OneSignal notification failed');
  }
}

async function toolsList(token, signOut) {
  try {
    const data = await fetchWrapper(`/tools`, { method: 'GET' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Fetching tools list failed');
  }
}

async function brokenToolsList(token, signOut) {
  try {
    const data = await fetchWrapper(`/tools/broken`, { method: 'GET' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Fetching broken tools list failed');
  }
}

async function toolDetails(toolSpecificId, token, signOut) {
  try {
    const data = await fetchWrapper(`/tools/history/${toolSpecificId}`, { method: 'GET' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Fetching tool details failed');
  }
}

async function getStatistics(token, signOut) {
  try {
    const data = await fetchWrapper(`/rentals/stats`, { method: 'GET' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Fetching statistics failed');
  }
}

async function changePasswordEmployee(password, token, signOut) {
  try {
    const data = await fetchWrapper(`/login/changePassword`, {
      method: 'POST',
      body: JSON.stringify({ newPassword: password })
    }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Changing password failed');
  }
}


// Skoða þetta aðeins betur fields eru ekki að birtast inni í töflunni.
async function addNewTool(name, serial, partNumber, location, isPhotoRequired, number, employeeId, customerId, token, signOut) {
  const newCode = `${name};${serial};${partNumber};${location};${isPhotoRequired}`;
  try {
    const data = await fetchWrapper(`/rentals/new`, {
      method: 'POST',
      body: JSON.stringify({
        code: newCode,
        number: parseInt(number, 10),
        employee_id: employeeId,
        customer_id: customerId,
      })
    }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Adding new tool failed');
  }
}

async function createProject(address, phone, name, date, information, employeeId, token, signOut) {
  try {
    const data = await fetchWrapper(`/rentals`, {
      method: 'POST',
      body: JSON.stringify({
        customerAddress: address,
        customerPhone: phone,
        customerName: name,
        createdDate: date,
        information,
        employeeId,
      })
    }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Creating project failed');
  }
}

async function editComment(information, projectId, token, signOut) {
  try {
    const data = await fetchWrapper(`/rentals/information`, {
      method: 'POST',
      body: JSON.stringify({
        information,
        projectId,
      }),
    }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Edit comment failed');
  }
}

export async function getAirplanes(token, signOut) {
  try {
    const data = await fetchWrapper(`/airplanes`, { method: 'GET' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Fetching airplanes failed');
  }
}

export async function postAirplanes({ airplaneName, airplaneCode, airplaneLocation, token, signOut }) {
  try {
    const data = await fetchWrapper(`/airplanes`, { method: 'POST', body: JSON.stringify({ name: airplaneName, code: airplaneCode, location_id: airplaneLocation }) }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Posting to airplanes');
  }
}

export async function updateAirplaneDetails({ airplaneId, airplaneName, airplaneCode, airplaneLocation, token, signOut }) {
  try {
    const data = await fetchWrapper(`/airplanes/${airplaneId}`, { method: 'PUT', body: JSON.stringify({ id: airplaneId, name: airplaneName, code: airplaneCode, location_id: airplaneLocation }) }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Updating airplane details');
  }
}

export async function getLocations(token, signOut) {
  try {
    const data = await fetchWrapper(`/locations`, { method: 'GET' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Fetching locations failed');
  }
}

export async function postLocations({ newCountry, newCode, newName, token, signOut }) {
  try {
    const data = await fetchWrapper(`/locations`, { method: 'POST', body: JSON.stringify({ country: newCountry, code: newCode, name: newName }) }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Posting to locations');
  }
}

export async function getEmployeeTools(id, token, signOut) {
  try {
    const data = await fetchWrapper(`/mytools/${id}/true`, { method: 'GET' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Fetching all employee failed');
  }
}

export async function getEmployeeToolsList(token, signOut) {
  try {
    const data = await fetchWrapper(`/mytools/employees`, { method: 'GET' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Fetching all employee failed');
  }
}

export async function getAllToolsRented(token, signOut) {
  try {
    const data = await fetchWrapper(`/rentals/alltools`, { method: 'GET' }, token, signOut);
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Fetching all rented tools failed');
  }
}

export { markToolRepairedApi, brokenToolsList, editComment, addNewTool, createProject, changePasswordEmployee, getStatistics, toolDetails, toolsList, projectList, closedProjectList, getProjectDetails, billProject, closeProject, openProject, stopRent, createEmployee, createTool, employeeList, updateEmployee, deleteEmployee, logoutEmployee, resetPasswordEmployee, sendOneSignalNotification, getAllRentals };