import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuthUser, useSignOut } from 'react-auth-kit';

import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  Alert,
  CircularProgress,
} from '@mui/material';

// sections
import { useForm } from 'react-hook-form';
import SendIcon from '@mui/icons-material/Send';
import { sendOneSignalNotification } from '../utils/api';

export default function DashboardAppPage() {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Add the loading state

  const resetMessages = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };

  const auth = useAuthUser();
  const signOut = useSignOut();

  const { token } = auth();

  const onSubmit = async (data) => {
    resetMessages();
    setIsLoading(true); // Set loading to true when submitting the form

    const { messageTitle, messageUrl, messageBody } = data;
    const title = messageTitle;
    const url = messageUrl;
    const body = messageBody;

    try {
      await sendOneSignalNotification(title, url, body, token, signOut);
      setSuccessMessage('A notification has been sent to the OneSignal system!');
      reset();
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred! Note that the title and text must be filled in. URL is optional.');
    } finally {
      setIsLoading(false); // Set loading to false when the operation is complete
    }
  };

  return (
    <>
      <Helmet>
        <title> Notifications | GMT ehf </title>
      </Helmet>

      <Container maxWidth="lg">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Send Notification
          </Typography>
        </Stack>
        <Card>
          {successMessage && (
            <Alert severity="success">
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="error">
              {errorMessage}
            </Alert>
          )}

          <Typography mx={4} my={4} variant="body1" color="gray">Note that notifications will arrive after a few minutes.</Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} sx={{ p: 3 }}>
              <Stack direction="row" spacing={2}>
                <TextField
                  {...register('messageTitle', { required: true })}
                  name="messageTitle"
                  label="Title"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.messageTitle}
                  helperText={errors.messageTitle && 'Please enter a title'}
                />
              </Stack>
              <Stack direction="column" spacing={0}>
                <Typography variant="body2" color="gray">Optional (A URL that opens if the user clicks on the notification)</Typography>
                <TextField
                  {...register('messageUrl', { required: false })}
                  name="messageUrl"
                  label="URL"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.messageUrl}
                  helperText={errors.messageUrl && 'Please enter a URL if you want to send one'}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextField
                  {...register('messageBody', { required: true })}
                  name="messageBody"
                  label="Text"
                  multiline
                  minRows={4}
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  error={!!errors.messageBody}
                  helperText={errors.messageBody && 'Please provide the desired content of the notification'}
                />
              </Stack>
            </Stack>
            <br /><br />
            {isLoading ? ( // Render the circular progress bar if loading is true
              <Button
                variant="contained"
                disabled
                startIcon={<CircularProgress size={20} />} // Add the circular progress icon
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  margin: '10px',
                }}
              >
                Stofna
              </Button>
            ) : (
              <Button
                variant="contained"
                type="submit"
                startIcon={<SendIcon />}
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  margin: '10px',
                }}
              >
                Send
              </Button>
            )}
          </form>
        </Card>
      </Container>
    </>
  );
}
