/* eslint-disable new-cap */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthUser, useSignOut } from 'react-auth-kit';
import QRCode from "react-qr-code";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  CircularProgress,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import QRGen from 'qrcode';
import { useQuery } from 'react-query';
// components
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import { toolsList } from '../utils/api';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'serial', label: 'Serial', alignRight: false },
  { id: 'partNumber', label: 'Part no.', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'qrCode', label: 'QR Code', alignRight: false },
  { id: 'view', label: '', alignRight: true },
  { id: 'exportPdf', label: '', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter(_tool => {
      const lowerQuery = query.toLowerCase();
      return (
        _tool.name.toLowerCase().includes(lowerQuery) ||
        _tool.serial.toLowerCase().includes(lowerQuery) ||
        _tool.partNumber.toLowerCase().includes(lowerQuery)
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TaekiPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('id');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [TOOL_LIST, setToolList] = useState([]);

  // const [loading, setLoading] = useState(true); // New loading state
  const [isPDFReady, setIsPDFReady] = useState(false);

  const { data, isLoading: loading } = useQuery('toolInventoryList', () => toolsList(token, signOut));

  const navigate = useNavigate();

  const auth = useAuthUser();
  const signOut = useSignOut();

  const { token } = auth();

  useEffect(() => {
    if (data) {
      setToolList(data);
    }
  }, [data]);

  const handleViewTool = (toolId) => {
    navigate(`/dashboard/tools/${toolId}`);
  }

  const handleExportQr = (e, toolId) => {
    e.stopPropagation();
    const tool = TOOL_LIST.find(t => t.id === toolId);
    if (tool) {
      setIsPDFReady(true);
      exportToPDF(`QR - TOOL nr. ${toolId}`, tool.qrCode);
    }
  };


  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const truncateString = (str, num) => (str.length > num ? `${str.slice(0, num)}..` : str);

  const exportToPDF = async (elementId, qrValue) => {
    const element = document.getElementById(elementId);
    const qrCanvas = document.createElement('canvas');
    document.body.appendChild(qrCanvas);

    try {
      await QRGen.toCanvas(qrCanvas, qrValue);

      const canvas = await html2canvas(qrCanvas);
      const data = canvas.toDataURL('image/png');

      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });

      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const qrCodeWidth = 128;
      const qrCodeHeight = 128;
      const posX = (pdfWidth - qrCodeWidth) / 2;
      const posY = 50;

      pdf.addImage(data, 'PNG', posX, posY, qrCodeWidth, qrCodeHeight);
      pdf.save(`${elementId}.pdf`);
    } catch (error) {
      console.error('Error generating QR code PDF:', error);
    } finally {
      document.body.removeChild(qrCanvas);
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - TOOL_LIST.length) : 0;

  const filteredUsers = applySortFilter(TOOL_LIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Tool Inventory | GMT ehf </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Tool Inventory
          </Typography>
        </Stack>
        {loading ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100%', minHeight: 300 }}
          >
            <CircularProgress color="primary" />
          </Stack>
        ) : (
          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={TOOL_LIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    displayCheckbox={false}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, name, serial, partNumber, location, qrCode } = row;
                      const selectedUser = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          onClick={() => handleViewTool(id)}
                          selected={selectedUser}
                          sx={{
                            "&:hover": {
                              cursor: 'pointer',
                            },
                          }}
                        >
                          <TableCell align="left">{id}</TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {truncateString(name, 40)}
                              </Typography>
                            </Stack>
                          </TableCell>

                          {/* Bætti við viðeigandi dálkum hér en Part no. og location ekki að birtist í Tool Inventory töflunni. */}
                          <TableCell align="left">{serial}</TableCell>
                          <TableCell align="left">{partNumber}</TableCell>
                          <TableCell align="left">{location}</TableCell>
                          <TableCell align="left">
                            <div id={`qrCodeContainer-${id}`} style={{ textAlign: 'center', paddingTop: '10px' }}>
                              <QRCode
                                size={48}
                                value={qrCode}
                                style={{ margin: 'auto' }}
                              // viewBox={`0 0 48 48`}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <Stack direction="row" spacing={1}> {/* Adjust the spacing value as needed */}
                              <Button size="small" variant="contained" onClick={(e) => handleExportQr(e, id)} startIcon={<Iconify icon="eva:download-fill" />}>
                                QR
                              </Button>
                              <Button size="small" variant="contained" onClick={() => handleViewTool(id)} startIcon={<Iconify icon="eva:eye-fill" />}>
                                History
                              </Button>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Nothing found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Check for spelling mistakes.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={TOOL_LIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
