/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

function chunkArray(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

const ClosedPDFExport = ({ filteredData }) => {
  const [download, setDownload] = useState(false);
  useEffect(() => {
    if (filteredData.length > 0 && !download) {
      createPDF();
    }
  }, [filteredData, download]);

  const createPDF = async () => {
    setDownload(true);
    const chunkedData = chunkArray(filteredData, 4); // Adjust 2 based on your needs
    const pdf = new jsPDF('p', 'pt', 'a4');

    const processChunk = async (chunk, idx) => {
      const convertDate = (date) => {
        const newDate = new Date(date);
        const day = newDate.getDate().toString().padStart(2, '0');
        const month = (newDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
        const year = newDate.getFullYear();
        const hours = newDate.getHours().toString().padStart(2, '0');
        const minutes = newDate.getMinutes().toString().padStart(2, '0');
        const seconds = newDate.getSeconds().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      };

      const htmlContent = `<div style="margin-left: 20px; padding-bottom: 20px;">${chunk.map(item => `
        <h4>Project ID: #${item.projectId}</h4>
        <p>Customer Name: ${item.customerName}</p>
        <p>Location: ${item.customerAddress}</p>
        <p>Date: ${convertDate(item.date)}</p>
        <p>Has been billed?: ${item.isBilled ? "Yes" : "No"}</p>
      `).join('')}</div>`;

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlContent;
      document.body.appendChild(tempDiv);

      await html2canvas(tempDiv, { scale: 0.95 }).then((canvas) => {
        if (idx > 0) {
          pdf.addPage();
        }
        pdf.addImage(canvas.toDataURL('image/jpeg', 0.75), 'JPEG', 0, 0);
      });

      document.body.removeChild(tempDiv);
    };

    for (let i = 0; i < chunkedData.length; i++) {
      await processChunk(chunkedData[i], i);
    }

    pdf.save('filteredprojects.pdf');

    // Redirect
    // window.location.href = '/dashboard/closed';
  };

  return null; // This component does not render anything itself
};

export default ClosedPDFExport;