/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useAuthUser, useSignOut } from 'react-auth-kit'
import { useMutation, useQuery } from 'react-query';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  CircularProgress,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TextField,
  TablePagination,
  Snackbar,
  Alert,
  IconButton,
} from '@mui/material';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// components
// import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import { getAirplanes, postAirplanes, getLocations, updateAirplaneDetails } from '../utils/api';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'name', label: 'Airplane Name', alignRight: false },
  { id: 'code', label: 'Airplane Code', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function VerkefniPage() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('projectId');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openDialog, setOpenDialog] = useState(false);

  // EDIT
  const [editAirplaneData, setEditAirplaneData] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const handleEditAirplane = (airplane) => {
    if (airplane) {
      setEditAirplaneData(airplane);
      // setAirplaneLocation(airplane.location.id);
      setOpenEditDialog(true);
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const [airplaneName, setAirplaneName] = useState('');
  const [airplaneCode, setAirplaneCode] = useState('');
  const [airplaneLocation, setAirplaneLocation] = useState('');

  const auth = useAuthUser();
  const signOut = useSignOut();

  const { token, id: employeeId } = auth();

  // const navigate = useNavigate();

  const { data: projectListData, isLoading, refetch } = useQuery('airplanes', () => getAirplanes(token, signOut));
  const { mutate, isLoading: isLoadingMutate, isError, isSuccess } = useMutation(postAirplanes);
  const { mutate: updateMutate, isLoading: isLoadingUpdate } = useMutation(updateAirplaneDetails);

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleViewAirplane = (projectId) => {
    // navigate(`/dashboard/project/${projectId}`);
  }

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleStofnaClick = async () => {
    await mutate({ airplaneName, airplaneCode, airplaneLocation, token, signOut });

    if (isError) {
      showSnackbar("Something went wrong, could not add new airplane", 'error');
      setOpenDialog(false);
    }

    setAirplaneName('');
    setAirplaneCode('');
    setAirplaneLocation('');
    showSnackbar("Airplane added successfully", 'success');
    setOpenDialog(false);
  };

  useEffect(() => {
    if (!isLoadingMutate && isSuccess) {
      refetch();
    }
  }, [isLoadingMutate, isSuccess]);

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   const day = String(date.getDate()).padStart(2, '0');
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const year = date.getFullYear();
  //   return `${day}/${month}/${year}`;
  // };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({ ...snackbar, open: false });
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleUpdateAirplane = async (data) => {
    await updateMutate(data, {
      onSuccess: () => {
        showSnackbar("Airplane updated successfully", 'success');
        setOpenEditDialog(false);
        refetch();
      },
      onError: () => {
        showSnackbar("Something went wrong, could not update airplane", 'error');
        setOpenEditDialog(false);
      }
    });
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - projectListData.length) : 0;

  const filteredUsers = applySortFilter(projectListData, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers?.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Airplanes | GMT ehf </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Airplanes
          </Typography>
          <Button variant="contained" onClick={handleOpenDialog} startIcon={<Iconify icon="eva:plus-fill" />}>
            Add Airplane
          </Button>
        </Stack>
        {isLoading ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100%', minHeight: 300 }}
          >
            <CircularProgress color="primary" />
          </Stack>
        ) : (
          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={projectListData.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    displayCheckbox={false}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      // eslint-disable-next-line camelcase
                      const { id, name, code } = row;
                      const selectedUser = selected.indexOf(code) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          onClick={() => handleViewAirplane(code)}
                          selected={selectedUser}
                          sx={{
                            "&:hover": {
                              cursor: 'pointer',
                            },
                          }}
                        >
                          <TableCell align="left">{id}</TableCell>

                          <TableCell>
                            <Typography noWrap>
                              {name}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography noWrap>
                              {code}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <IconButton onClick={() => handleEditAirplane(row)}>
                              <Iconify icon="eva:edit-fill" />
                            </IconButton>
                          </TableCell>

                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Nothing found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Check for spelling mistakes.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={projectListData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      <Dialog fullWidth="md" open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add a new Airplane</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill in the required information
          </DialogContentText>
          {/* Þetta þarf að vera dropdown með locations  */}
          <TextField
            margin="dense"
            id="airplaneName"
            label="Airplane Name"
            type="text"
            fullWidth
            value={airplaneName}
            onChange={e => setAirplaneName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="airplaneCode"
            label="Code"
            type="text"
            fullWidth
            value={airplaneCode}
            onChange={e => setAirplaneCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button variant="contained" disabled={isLoading} onClick={handleStofnaClick} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        fullWidth
        maxwidth="sm"
        PaperProps={{
          style: {
            minHeight: '300px',
            width: '600px',
          }
        }}
      >
        <DialogTitle>Edit Airplane</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            label="Airplane Name"
            type="text"
            fullWidth
            value={editAirplaneData?.name || ''}
            onChange={(e) => setEditAirplaneData({ ...editAirplaneData, name: e.target.value })}
          />
          <TextField
            margin="dense"
            id="code"
            label="Airplane Code"
            type="text"
            fullWidth
            value={editAirplaneData?.code || ''}
            onChange={(e) => setEditAirplaneData({ ...editAirplaneData, code: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button onClick={() => handleUpdateAirplane({
            airplaneId: editAirplaneData.id,
            airplaneName: editAirplaneData.name,
            airplaneCode: editAirplaneData.code,
            token
          })}>Update</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
