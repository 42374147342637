/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

function chunkArray(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

const InOutPDFExport = ({ filteredData }) => {
  const [download, setDownload] = useState(false);

  useEffect(() => {
    if (filteredData.length > 0 && !download) {
      createPDF();
    }
  }, [filteredData, download]);

  const createPDF = async () => {
    setDownload(true);
    const chunkedData = chunkArray(filteredData, 4); // Adjust 2 based on your needs
    const pdf = new jsPDF('p', 'pt', 'a4');

    const processChunk = async (chunk, idx) => {
      const htmlContent = `<div style="margin-left: 20px; padding-bottom: 20px;">${chunk.map(item => `
        <h4>Tool: ${item.name}</h4>
        <p>Serial: ${item.serial}</p>
        <p>Customer Address: ${item.customerAddress}</p>
        <p>Employee: ${item.employeeFirstName} ${item.employeeLastName}</p>
        <p>Out: ${item.rentStartDate}</p>
        <p>In: ${item.rentEndDate ? item.rentEndDate : 'N/A'}</p>
      `).join('')}</div>`;

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlContent;
      document.body.appendChild(tempDiv);

      await html2canvas(tempDiv, { scale: 0.95 }).then((canvas) => {
        if (idx > 0) {
          pdf.addPage();
        }
        pdf.addImage(canvas.toDataURL('image/jpeg', 0.75), 'JPEG', 0, 0);
      });

      document.body.removeChild(tempDiv);
    };

    for (let i = 0; i < chunkedData.length; i++) {
      await processChunk(chunkedData[i], i);
    }

    pdf.save('filteredprojects.pdf');
  };

  return null; // This component does not render anything itself
};

export default InOutPDFExport;